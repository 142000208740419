:root {
    --skeleton-block-default-padding: 2rem 0;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family:
        Roboto,
        Segoe UI,
        -apple-system,
        BlinkMacSystemFont,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif;
    color: var(--default-dark, #000);
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: var(--default-primary, var(--wb-blue));
}

.richtexteditor {
    a {
        color: var(--default-primary, var(--wb-blue));
        text-decoration: underline;
    }

    .richtext-image.right {
        margin-left: auto;
        display: flex;
    }

    .richtext-image.left {
        margin-right: auto;
        display: flex;
    }

    .richtext-image.center {
        margin-left: auto;
        margin-right: auto;
        display: flex;
    }

    iframe {
        margin-left: auto;
        margin-right: auto;
        display: flex;
    }
}

.bg-default-light {
    background-color: var(--default-light);
}

.bg-default-dark {
    background-color: var(--default-dark);
}

.bg-default-primary {
    background-color: var(--default-primary);
}

.bg-default-secondary {
    background-color: var(--default-secondary);
}
